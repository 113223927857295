/* eslint-disable vue/require-prop-types */
<template>
    <div class="container">
        <section id="calendar-add-dias" class="row justify-content-center calendar-add-dias">
            <template >
                <div v-for="(mes,i) in calendarioAnnio" :key="i" class="meses mx-3">
                    <div class="mes-nombre d-middle mb-2">
                        <p class="px-2 text-capitalize">{{mes.nombre}}</p>
                    </div>
                    <el-popover placement="right" width="170" trigger="hover">
                        <div class="f-12 text-70 f-300">
                            <p>Etapas: {{mes.etapas}}</p>
                            <p>Configuraciones: {{mes.configuraciones}}</p>
                            <p>Material: {{mes.materiales}}</p>
                            <p>P. mantenimiento: {{mes.planes_mantenimiento}}</p>
                            <p>Actividades: {{mes.actividades}}</p>
                            <p>Pasos: {{mes.pasos}}</p>
                        </div>
                        <div class="dias" slot="reference">
                            <div v-for="(dia,k) in data_dias" :key="`${i}-${k}`" class="letra-dia f-600 mb-1">
                                <p>{{dia}}</p>
                            </div>
                            <div v-for="(dia2,m) in obtener_dias(mes)" :key="m" class="numd">
                                    <p :id="`popover-${i}-${dia2.id}`" slot="reference" class="wh-22 rounded-circle d-middle-center f-10" :class="`wh-22 rounded-circle px-0 mb-0 ${MostrarDias(dia2.id,mes, i)}` " style="cursor:pointer;" @click="MostrarPopoverSimple(dia2.id, mes)">
                                        {{dia2.id}}
                                    </p>
                            </div>
                        </div>
                    </el-popover>
                    <p class="btn-detalle bg-general text-white br-3 f-10 f-400 px-2 py-1 cr-pointer shadow29 mx-auto" @click="verDetalle">Ver detalle</p>
                </div>
            </template>
            
        </section>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import moment from 'moment';
export default {
    name: 'CalendarioPorMes',
    data(){
        return {
            //
            varios: false,
            show: false,
            seleccionado: {},
            data_dias: {
                1:'D',
                2:'L',
                3:'M',
                4:'M',
                5:'J',
                6:'V',
                7:'S'
            },
            Formulario: [],
            FormularioGrupo: [],
            array_data: [],
        }
    },
    computed: {
        ...mapGetters({
            calendarioAnnio: 'calendario/calendario/calendarioAnnio',
        }),
        ano_actual(){
            return moment().year()
        },
        data_mes(){
            let primero = moment(`${moment().format('YYYY')}-01-01`)
            let ultimo = moment(`${moment().add(1, 'Y').format('YYYY')}-01-01`)
            // var primero = moment().subtract(1, 'M')
            // var ultimo = moment().add(10, 'M')
            var array = []
            while (primero < ultimo){
                array.push({
                    mes: primero.format('MM'),
                    nombre: primero.format('MMMM'),
                    ano: primero.format('Y')
                })
                primero = primero.add(1, 'M')
            }
            return _.orderBy(array, ['ano'], ['asc'])
        }
    },
    async created(){
        // const payload = {}
        // await this.Action_get_calendario_annio(payload);
    },
    methods: {
        ...mapActions({
            Action_get_calendario_annio: 'calendario/calendario/Action_get_calendario_annio',
        }),

        /**
        * [MostrarDias juan gomez 23 de octubre]
        */
        MostrarDias(dia, mes, index){
            if (mes.dias.some(d => d == dia)) {
                return 'text-contactos text-light'
            }
        },


        /**
        * [MostrarPopoverSimple juan gomez 23 de octubre]
        */
        MostrarPopoverSimple(dia, mes){
            // console.log('MostrarPopoverSimple',dia, mes);

            let fecha = moment(`${mes.ano}-${mes.mes}-${dia}`, 'Y-MM-DD').format('MM-DD')
            let formulario = _.filter(this.array_data, o => { return moment(o.fecha_comparativa, 'Y-MM-DD').format('MM-DD') === fecha })
            if (formulario.length === 1){

                this.$emit(`${_.head(formulario).tipo_color}`,_.head(formulario))
                this.FormularioGrupo = []
                this.Formulario = formulario
                $('#calendar-add-dias').trigger('click');

            } else if (formulario.length > 1){

                this.Formulario = []
                formulario.forEach(element => {
                    element.tipo_color

                    switch (element.tipo_color){
                        case 'contacto':
                            element.color = '#079CE5'
                            break;
                        case 'funcionario':
                            element.color = '#13638A'
                            break;
                        case 'cliente':
                            element.color = '#00B41E'
                            break;
                        case 'aliado':
                            element.color = '#E5A207'
                            break;
                        default:
                            break;
                    }
                });
                this.FormularioGrupo = formulario

            } else {

                this.FormularioGrupo = []
                this.Formulario = []

            }
        },

        /**
        * [MostrarPopoverGrup juan gomez 23 de oct]
        * @param {[type]} id [description]
        */
        MostrarPopoverGrup(data){
            this.$emit(`${data.tipo_color}`,data)
            $('#calendar-add-dias').trigger('click');
        },

        obtener_dias(tmp_mes){
            let that = this
            var diaSemana = moment(`${tmp_mes.ano}-${tmp_mes.mes}-1`,'YYYY-MM-DD').format('e')
            var ultimodia = moment(`${tmp_mes.ano}-${tmp_mes.mes}`,'YYYY-MM').daysInMonth()
            var mes = moment(`${tmp_mes.ano}-${tmp_mes.mes}`,'YYYY-MM').format('MMMM')

            let tmp = []
            switch (parseInt(diaSemana)){
                case 5:{
                    for (var k = 1; k <= 6 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 4:{
                    for (var k = 1; k <= 5 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 3:{
                    for (var k = 1; k <= 4 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 2:{
                    for (var k = 1; k <= 3 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 1:{
                    for (var k = 1; k <= 2 ; k++){
                        tmp.push('')
                    }
                }
                    break;
                case 0:{
                    for (var k = 1; k <= 1 ; k++){
                        tmp.push('')
                    }
                }
                    break

                default:
            }

            for (var i = 1; i <= ultimodia ; i++){
                tmp.push({id:i,show:false})
            }
            return tmp
        },
        verDetalle(){
            this.$emit('verDetalle')
        }
    }
}
</script>

<style lang="scss" scoped>
.calendar-add-dias{
    $clientes:#00B41E;
    $contactos:#079CE5;
    $aliados:#E5A207;
    $funcionarios:#13638A;
    .text{
        &-clientes{
            background: $clientes;
        }
        &-contactos{
            background: $contactos
        }
        &-aliados{
            background: $aliados;
        }
        &-funcionarios{
            background: $funcionarios;
        }
    }
    .meses{
        max-width: 240px !important;
        min-height: 200px !important;
        margin-bottom: 30px;
        .mes-nombre {
            width: 90% !important;
            font-size: 12px;
            font-weight: 700;
        }
        .dias {
            //float: left;
            //width: 90%;
            max-width: 245px !important;
            height: 200px;
            .letra-dia {
                float: left;
                font-size: 12px;
                width: 14.2%;
                text-align: center;
                color: var(--text-dark);
                max-width: 35px !important;
                font-weight: 600;
            }
            .numd {
                font-size: 12px;
                color: var(--text-dark);
                float: left;
                width: 14.2%;
                text-align: center;
                min-height: 30px;
                position: relative;
                max-width: 35px !important;
                .bg-haveinfo{
                    background: #3CD273;
                    color: #fff;
                }
            }
        }
    }
}
.popo{
    z-index:999;
    position:absolute;
    height:50px;
    width:50px;
}

.ano {
    position: absolute;
    left: 0px;
    transform: translateY(-50%) !important;
    top: 50% !important;
}

.dia_actual{
    background: #0090ff;
    color: var(--general-text-color);
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50%;
}
.dia_sel{
    position: absolute;
    height: 25px;
    width: 25px;
    background: gray;
    color: var(--general-text-color);
    padding: 2px;
    border-radius: 50%;
}

.valdia p {
    font-size: 10px;
    font-weight: 300;
}
.valdia {
    position: absolute;
    background: var(--general-text-color);
border-radius: 50%;
    left: 34%;
    top: 0px;
    min-width: 16px;
}
.diaConAlertas{
    background: #0090ff;
    color: var(--general-text-color);
    border-radius: 50%;
    cursor: pointer;
}
.diaSemana{
    background-color: #0090FF;
    border-radius: 50%;
    color: var(--general-text-color);
    padding-left: 3px;
    padding-right: 3px;
}
.text-70{
    color: #707070;
}
.btn-detalle{
    width: 98px;
    text-align: center;
    height: 25px;
}


</style>
