<template>
    <modal ref="modalFechaCercanas" titulo="Fechas próximas" tamano="modal-xl">
        <div class="row justify-content-center mx-3 f-15 mt-3">
            <div class="col-12 nearby-dates-notification">
                 <el-collapse v-model="activeName" accordion>
                    <el-collapse-item v-for="(data, index) in fechasProximas" :key="index" name="1">
                        <template slot="title">
                            <div class="row f-600 w-100">
                                <div class="col-5 offset-2">
                                    <div class="d-flex">
                                        <p class="text-general">Oportunidad:</p>
                                        <p class="pl-3 text-5d">{{data.oportunidad}}</p>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="d-flex">
                                        <p class="text-general">Producto:</p>
                                        <p class="pl-3 text-5d">{{data.producto}}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="row">
                            <div class="col-12">
                                <div class="table-responsive tabla-header-general">
                                    <table class="w-100">
                                        <thead align="center">
                                            <tr>
                                                <th>Etapa</th>
                                                <th>Configuración</th>
                                                <th>Material</th>
                                                <th>P. mantenimiento</th>
                                                <th>Actividad</th>
                                                <th>Fecha próxima</th>
                                            </tr>
                                        </thead>
                                        <tbody class="f-12 text-5d" align="center">
                                            <tr v-for="(etapa, index) in data.etapas" :key="index">
                                                <td>
                                                    <p>{{etapa.nombre}}</p>
                                                </td>
                                                <td>
                                                    <p v-for="(config, idx) in etapa.configuraciones" :key="idx" >{{config.nombre}}</p>
                                                </td>
                                                <td>
                                                    <div v-for="(config, idx) in etapa.configuraciones" :key="idx">
                                                        <p v-for="(material, idxM) in config.materiales" :key="idxM">{{material.nombre}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-for="(config, idx) in etapa.configuraciones" :key="idx">
                                                        <p v-for="(material, idxM) in config.materiales" :key="idxM">{{material.mantenimiento}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-for="(config, idx) in etapa.configuraciones" :key="idx">
                                                        <div v-for="(material, idxM) in config.materiales" :key="idxM">
                                                            <p v-for="(actividad, idxA) in material.actividades" :key="idxA">{{actividad.nombre}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-for="(config, idx) in etapa.configuraciones" :key="idx">
                                                        <div v-for="(material, idxM) in config.materiales" :key="idxM">
                                                            <p v-for="(actividad, idxA) in material.actividades" :key="idxA">{{actividad.fecha}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            activeName:'',
        }
    },
    computed: {
        ...mapGetters({
            fechasProximas: 'calendario/calendario/fechasProximas',
        }),
    },
    async created(){
        await this.Action_get_ops_fechas_proximas()
    },
    methods: {
        toggle(){
            this.$refs.modalFechaCercanas.toggle()
        },
        crearTarea(){
            this.toggle()
        },
        ...mapActions({
            Action_get_ops_fechas_proximas: 'calendario/calendario/Action_get_ops_fechas_proximas',
        }),
    }
}
</script>

<style lang="scss" scoped>
.nearby-dates-notification{
    table{
        tbody{
            tr{
                td{
                    vertical-align: top;
                }
            }
        }
        
    }
}
</style>
