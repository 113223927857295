<template>
    <section>
        <navbar-admin icon="icon-calendar-multiselect" />
        <calendario-anio @verDetalle="verDetalle" />
        <!-- partials  -->
        <modal-fechas-cercanas ref="modalFechasCercanas" />
    </section>
</template>

<script>
import calendarioAnio from './components/calendarioAnio.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import modalFechasCercanas from './partials/modalFechasCercanas.vue'
export default {
    components:{
        calendarioAnio,
        modalFechasCercanas,
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            fechasProximas: 'calendario/calendario/fechasProximas',
        }),
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.year'
        ]);
    },
    mounted(){
        this.showNearByDate()
    },
    methods: {
        ...mapActions({
            Action_get_ops_fechas_proximas: 'calendario/calendario/Action_get_ops_fechas_proximas',
        }),
        async showNearByDate(){
            await this.Action_get_ops_fechas_proximas()
            if (!this.fechasProximas.length) return
            this.$refs.modalFechasCercanas.toggle()
        },
        verDetalle(){
            this.$router.push({ name: 'calendario.ver.detalle' })
        },
        setData(){}
    },
}
</script>

<style>

</style>